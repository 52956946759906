<template>
  <div>
    <z-responsive-modal
      :show.sync="showModal"
      hide-close
      size="md"
      @hide="handleClose"
    >
      <form-create-client @close="handleClose" />
    </z-responsive-modal>
  </div>
</template>

<script>
import FormCreateClient from "./FormCreateClient";
import ZResponsiveModal from "@zubut/common/src/components/ZResponsiveModal";
import * as mutations from "@/store/modules/clients/mutations-types";

export default {
  name: "ClientCreate",

  components: {
    FormCreateClient,
    ZResponsiveModal
  },

  data() {
    return {
      showModal: true
    };
  },

  methods: {
    handleClose() {
      this.$router.push({ name: "clients", query: {} });
      this.showModal = false;
      this.$store.commit(`clients/${mutations.RESET_CLIENT_CREATION}`);
    }
  }
};
</script>
