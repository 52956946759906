export default {
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isDesktop() {
      if (screen.width >= 991) {
        return true;
      } else {
        return false;
      }
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  }
};
